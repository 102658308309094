import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const SoluzioniLeader = () => {
  const { soluzione } = useStaticQuery(graphql`
    {
      soluzione: file(relativePath: { eq: "soluzione-leader.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={`Intranet 80.20, la soluzione leader sul mercato`}
        description={`Scopri l'unica soluzione di Intranet pensata sulla base dell'esperienza e delle best practices internazionali. Garantisce funzionalità e design d'eccellenza.`}
      />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-9">
                <h1 className="hero__title">
                  La <strong>soluzione leader</strong> progettata da un team di eccellenza
                </h1>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase leader">Leader</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase leader">Leader</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__intro">
          <div className="container-lg">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row align-items-end">
                  <div className="page-detail__intro-text">
                    <p>
                      L'unica soluzione di intranet pensata dal massimo esperto italiano in materia,{" "}
                      <strong>Giacomo Mason</strong>, sulla base delle best practices del settore.
                    </p>
                  </div>
                  <div className="page-detail__img-wrap">
                    <GatsbyImage
                      image={getImage(soluzione)}
                      alt="img soluzione leader"
                      objectPosition="center center"
                      className="page-detail__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <p>
                  Si tratta della soluzione di Intranet <strong>allo stato dell’arte</strong>,
                  progettata dal massimo specialista italiano in materia, Giacomo Mason, sulla base
                  dell’esperienza e delle <strong>best practices</strong> internazionali.
                </p>
                <p>
                  Costituisce una <strong>sintesi ideale</strong> di funzionalità e design che
                  rappresenta una “formidabile scorciatoia” verso un risultato finale d’eccellenza.
                </p>
                <h2>Giacomo Mason</h2>
                <p>
                  Consulente, founder di{" "}
                  <a
                    href="https://www.intranetmanagement.it/"
                    title="Intranet Management"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Intranet Management
                  </a>
                  , promotore di{" "}
                  <a
                    href="https://www.intranetitaliaday.it/"
                    title="Intranet Italia Day"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Intranet Italia Day
                  </a>
                  , il principale evento italiano in materia. Mason da oltre 20 anni è impegnato ad
                  aiutare le aziende nel migliorare la comunicazione interna con progetti user
                  centered design.
                </p>
                <h2>Ariadne Group</h2>
                <p>
                  <a
                    href="https://www.ariadnegroup.it"
                    title="Ariadne Group - the Digital Compass"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ariadne Group
                  </a>{" "}
                  è un insieme di aziende ultra specialistiche creato per esprimere{" "}
                  <strong>eccellenza nel settore Digital</strong>.
                </p>
                <p>
                  Vanta un’esperienza di oltre 25 anni nella realizzazione di intranet e portali per
                  le aziende e comprende al proprio interno un team di lavoro dedicato alla gestione
                  e sviluppo di progetti su Intranet 80.20.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SoluzioniLeader;
